import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-00d7e878"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "about flex-column" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_about_header = _resolveComponent("about-header")!
  const _component_about_info = _resolveComponent("about-info")!
  const _component_about_production = _resolveComponent("about-production")!

  return (_openBlock(), _createElementBlock("section", _hoisted_1, [
    _createVNode(_component_about_header),
    _createVNode(_component_about_info),
    _createVNode(_component_about_production)
  ]))
}