
import { defineComponent } from "vue-demi";
import AboutMain from "@/components/main/about/index.vue";

export default defineComponent({
  name: "AboutView",
  components: {
    AboutMain,
  },
});
