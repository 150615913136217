
import { defineComponent } from "vue-demi";
import AboutHeader from './modules/header/index.vue';
import AboutInfo from './modules/info/index.vue';
import AboutProduction from './modules/production/index.vue';

export default defineComponent({
  name: 'MainAboutMain',
  components: {
    AboutHeader,
    AboutInfo,
    AboutProduction,
  }
})
